import { APP_ROUTES } from 'config/app-routes';
import Layout from 'layout/applayout';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import AccessDenied from 'pages/access-denied';
import Logout from 'pages/logout';
import NotFound from 'pages/not-found';
import PrivacyPolicyPage from 'pages/privacy-policy';
import TermsAndConditionsPage from 'pages/terms-and-conditions';
import TradingTermsAndConditionsPage from 'pages/trading-terms-and-conditions';

import PageLoader from 'components/shared-components/page-loader';

// const AppMainLandingPage = lazy(() => import("pages/main-landing-page"));
const MainLandingPage = lazy(() => import('pages/welcome-landing-page'));
const CommodityPermissionMain = lazy(() => import('pages/commodity-main-page'));
const Profile = lazy(() => import('pages/profile'));
const CommodityProfilePage = lazy(() => import('pages/commodity-profile-page'));
const UserListingPage = lazy(() => import('pages/user-listing-page'));
const InvitesListingPage = lazy(() => import('pages/invites-listing-page'));
const MyPortals = lazy(() => import('pages/my-portals'));
const UserRole = lazy(() => import('pages/user-role'));
const FcoGroup = lazy(() => import('pages/fco-group'));
const CommodityListing = lazy(() => import('pages/commodity-listing'));
const UserType = lazy(() => import('pages/user-type'));
const CreateUserRole = lazy(() => import('pages/create-user-role'));
const EditUserRole = lazy(() => import('pages/edit-user-role'));
const Dashboard = lazy(() => import('pages/dashboard'));
// const MenuItemPage = lazy(() => import("pages/menu-item"));

const Checklist = lazy(() => import('pages/checklist'));
const PricingManager = lazy(() => import('pages/pricing-manager'));
const PVList = lazy(() => import('pages/pv-list'));
const InverterList = lazy(() => import('pages/inverter-list'));
const BatteryStorage = lazy(() => import('pages/battery-storage'));
// const ElectricityProvider = lazy(() => import("pages/electricity-provider"));
const InstallersList = lazy(() => import('pages/installers-list'));
const SWHList = lazy(() => import('pages/swh-list'));
const CreateProfilePage = lazy(() => import('pages/create-profile'));

const ProtectedRoutes = () => {
  const loggedInUser = useSelector((state) => state.user.user);

  const LayoutSuspense = ({ children }) => {
    return (
      <Layout>
        <Suspense fallback={<PageLoader />}>{children}</Suspense>
      </Layout>
    );
  };

  return (
    <Routes>
      <Route
        path={APP_ROUTES.private.HOME}
        exact
        element={
          <Navigate
            replace
            to={APP_ROUTES.private.PROFILE(loggedInUser?.userTypeID, loggedInUser?.id)}
          />
        }
      />
      <Route
        path={APP_ROUTES.public.SIGNIN}
        exact
        element={
          <Navigate
            replace
            to={APP_ROUTES.private.PROFILE(loggedInUser?.userTypeID, loggedInUser?.id)}
          />
        }
      />
      <Route
        path={APP_ROUTES.public.AUTHENTICATION_CALLBACK}
        element={
          <Suspense fallback={<PageLoader />}>
            <MainLandingPage />
          </Suspense>
        }
      />
      {/* {loggedInUser?.userTypeID !== userType?.CompanyStaff &&
      loggedInUser?.userTypeID !== userType?.SolarElectrician ? (
        <Route
          path="/create-user"
          element={
            <LayoutSuspense>
              <CommodityPermissionMain invite={false} />
            </LayoutSuspense>
          }
        />
      ) : null} */}

      <Route
        path={APP_ROUTES.private.INVITE_USER}
        element={
          <LayoutSuspense>
            <CommodityPermissionMain />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.SENT_INVITES}
        element={
          <LayoutSuspense>
            <InvitesListingPage />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.EDIT_PROFILE}
        element={
          <LayoutSuspense>
            <Profile />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.PROFILE(':userType', ':id')}
        element={
          <LayoutSuspense>
            <CommodityProfilePage />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.CREATE_PROFILE(':userType', ':id')}
        element={
          <LayoutSuspense>
            <CreateProfilePage />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.USERS}
        element={
          <LayoutSuspense>
            <UserListingPage />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.MY_PORTALS}
        element={
          <LayoutSuspense>
            <MyPortals />
          </LayoutSuspense>
        }
      />
      {/* <Route
        path={APP_ROUTES.private.USER_TYPE}
        element={
          <LayoutSuspense>
            <UserType />
          </LayoutSuspense>
        }
      /> */}
      <Route
        path={APP_ROUTES.private.USER_ROLE}
        element={
          <LayoutSuspense>
            <UserRole />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.CREATE_USER_ROLE}
        element={
          <LayoutSuspense>
            <CreateUserRole />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.EDIT_USER_ROLE(':id')}
        element={
          <LayoutSuspense>
            <EditUserRole />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.AUDITOR_GROUP}
        element={
          <LayoutSuspense>
            <FcoGroup />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.COMMODITY_LISTING}
        element={
          <LayoutSuspense>
            <CommodityListing />
          </LayoutSuspense>
        }
      />
      {/* <Route
        path="/menu"
        element={
          <LayoutSuspense>
            <MenuItemPage />
          </LayoutSuspense>
        }
      /> */}
      {/* <Route
        path={APP_ROUTES.private.DASHBOARD}
        element={
          <LayoutSuspense>
            <Dashboard />
          </LayoutSuspense>
        }
      /> */}
      <Route
        path={APP_ROUTES.private.ACCESS_DENIED}
        element={
          <Layout>
            <AccessDenied />
          </Layout>
        }
      />

      <Route
        path={APP_ROUTES.private.REQUIREMENTS}
        element={
          <LayoutSuspense>
            <Checklist />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.PRICING_MANAGER}
        element={
          <LayoutSuspense>
            <PricingManager />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.PV_LIST}
        element={
          <LayoutSuspense>
            <PVList />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.INVERTER_LIST}
        element={
          <LayoutSuspense>
            <InverterList />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.BATTERY_STORAGE}
        element={
          <LayoutSuspense>
            <BatteryStorage />
          </LayoutSuspense>
        }
      />
      {/* <Route
        path="/electricity-provider"
        element={
          <LayoutSuspense>
            <ElectricityProvider />
          </LayoutSuspense>
        }
      /> */}
      <Route
        path={APP_ROUTES.private.INSTALLER_LIST}
        element={
          <LayoutSuspense>
            <InstallersList />
          </LayoutSuspense>
        }
      />
      <Route
        path={APP_ROUTES.private.SWH_LIST}
        element={
          <LayoutSuspense>
            <SWHList />
          </LayoutSuspense>
        }
      />

      <Route path={APP_ROUTES.private.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />

      <Route path={APP_ROUTES.private.LOGOUT} element={<Logout />} />

      <Route path={APP_ROUTES.private.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
      <Route
        path={APP_ROUTES.private.TRADING_TERMS_AND_CONDITIONS}
        element={<TradingTermsAndConditionsPage />}
      />

      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default ProtectedRoutes;
