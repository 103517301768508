import React from 'react';

import SpinnerLoader from '../spinner-loader';

const PageLoader = () => {
  return (
    <div className='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
      <SpinnerLoader />
      loading.....
    </div>
  );
};

export default PageLoader;
