import React from 'react';
import Modal from 'react-bootstrap/Modal';

import styles from './modal.module.css';

const CustomModal = ({ show, handleClose, title, body, size, customWidth }) => {
  return (
    <>
      <Modal
        show={show}
        size={size ? 'lg' : 'md'}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={handleClose}
        backdrop='static'
        className={customWidth && styles.customWidth}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter' className={styles.modalTitle}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
      </Modal>
    </>
  );
};

export default CustomModal;
