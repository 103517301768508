import { APP_ROUTES } from 'config/app-routes';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import NotFound from 'pages/not-found';
import PrivacyPolicyPage from 'pages/privacy-policy';
import TermsAndConditionsPage from 'pages/terms-and-conditions';
import TermsAndPrivacyPolicy from 'pages/terms-and-privacy-policy';
import TradingTermsAndConditionsPage from 'pages/trading-terms-and-conditions';

import PageLoader from 'components/shared-components/page-loader';

const AppMainLandingPage = lazy(() => import('pages/main-landing-page'));
const MainLandingPage = lazy(() => import('pages/welcome-landing-page'));
const Signup = lazy(() => import('pages/sign-up'));
const SignupElectrician = lazy(() => import('pages/sign-up/signup-electrician'));
const SignupSolarCompany = lazy(() => import('pages/sign-up/signup-solar-company'));
const Signin = lazy(() => import('pages/sign-in'));
const ForgotPasswordPage = lazy(() => import('pages/forgot-password-page'));
const UserInvitedPage = lazy(() => import('pages/user-invited'));
const UpdateEmailPage = lazy(() => import('pages/update-email'));
const UpdateMobileNoPage = lazy(() => import('pages/update-mobile-no'));

const UnProtectedRoutes = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path={APP_ROUTES.public.HOME} element={<AppMainLandingPage />} />
        <Route path={APP_ROUTES.public.AUTHENTICATION_CALLBACK} element={<MainLandingPage />} />

        <Route path={APP_ROUTES.public.SIGNUP(':id')} element={<Signup />} />
        <Route path={APP_ROUTES.public.SIGNUP_ELECTRICIAN} element={<SignupElectrician />} />

        {/* <Route
          path={"/sign-up-solar-retailer/:resellerId"}
          element={<SignupSolarCompany />}
        /> */}
        <Route
          path={APP_ROUTES.public.SIGNUP_SOLAR(':resellerId')}
          element={<SignupSolarCompany />}
        />
        <Route path={APP_ROUTES.public.SIGNIN} element={<Signin />} />
        <Route path={APP_ROUTES.public.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={APP_ROUTES.public.USER_INVITED} element={<UserInvitedPage />} />
        <Route path={APP_ROUTES.public.UPDATE_EMAIL} element={<UpdateEmailPage />} />
        <Route path={APP_ROUTES.public.UPDATE_MOBILE_NO} element={<UpdateMobileNoPage />} />
        <Route path={APP_ROUTES.public.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
        <Route path={APP_ROUTES.public.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
        <Route
          path={APP_ROUTES.public.TRADING_TERMS_AND_CONDITIONS}
          element={<TradingTermsAndConditionsPage />}
        />
        <Route
          path={APP_ROUTES.public.TERMS_AND_PRIVACY_POLICY}
          element={<TermsAndPrivacyPolicy />}
        />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default UnProtectedRoutes;
