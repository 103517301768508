export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const onlyNumRegExp = /^[0-9]*$/;

export const onlyDigitsRegex = /^\d*$/;

export const onlyAlphabetsRegex = /^[A-Za-z ]+$/;

export const bsbRegex = /^\d{6}$/;

export const otpRegex = /^\d{6}$/;

export const mobileReg = /^04\d{8}$/;

export const mobileNumberRegex = /^04\d*$/;

export const GSTReg = /^\d*\.?\d+$/;

export const removeSpacesRegex = /\s/g;

export const passwordRegex =
  // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?=&#\[\]\(\)\-\+\^])[A-Za-z\d@$!%*?=&#\[\]\(\)\-\+\^]{8,}$/;
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[^\s]{8,}$/;
