import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { UserStatus } from 'constants/index';

import Toaster from 'utils/toastify';

import PendingUserRoutes from './pending-user-routes';
import ProtectedRoutes from './protected-routes';
import UnprotectedRoutes from './unprotected-routes';

const AllRoutes = () => {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.user.user);

  return (
    <Router>
      <Toaster />
      {token && user ? (
        <>{user?.statusId === UserStatus.Approved ? <ProtectedRoutes /> : <PendingUserRoutes />}</>
      ) : (
        <UnprotectedRoutes />
      )}
    </Router>
  );
};

export default AllRoutes;
