export const userType = {
  SuperAdmin: 1,
  ComplianceOfficer: 2,
  Reseller: 3,
  AccountManager: 4,
  SolarCompany: 5,
  CompanyStaff: 6,
  SolarElectrician: 7
};

export const UserStatus = {
  Pending: 1,
  Approved: 2,
  Rejected: 3,
  RequiredMorePaperWork: 4
};

export const UserStatusName = {
  1: 'Pending',
  2: 'Approved',
  3: 'Rejected',
  4: 'RequiredMorePaperWork'
};

export const userRole = {
  Commodity: 1,
  UserType: 2,
  User: 3,
  Role: 4,
  AuditorGroup: 5,
  Requirement: 6,
  Menu: 7,
  Invite: 8,
  Apps: 9,
  ApprovedPVModule: 10,
  InverterList: 11,
  SAAAccreditedInstallersList: 12,
  SWHList: 13,
  PricingManager: 14,
  BatteryStorageList: 15,
  ElectricityProvider: 16,
  Job: 17,
  Profile: 18,
  UpdateEmail: 19,
  UpdateUserDocStatus: 20,
  UpdateUserStatus: 21,
  ComplianceCheck: 22,
  RequirementCaptureScan: 23,
  RequirementCapture: 24,
  RequirementCaptureSignature: 25,
  RequirementCesToPdf: 26,
  RequirementScanNumber: 27,
  UpdateMobileNo: 28,
  PersonalDetails: 29,
  AccountDetails: 30,
  WholesalerDetails: 31,
  InstallerDesignerDetails: 32,
  ConnectedDevices: 33,
  SaasDetails: 34,
  RecCode: 35,
  JobDocument: 36,
  JobVisitStatus: 37
};
export const operationId = {
  Create: 1,
  Read: 2,
  Update: 3,
  Delete: 4,
  Assign: 5,
  SelectAll: 6
};

export const SelectOption = { id: 0, name: 'Select' };

export const searchSystemSizeDropdown = [
  {
    id: 0,
    name: 'Search System < 60kW',
    value: false
  },
  {
    id: 1,
    name: 'Search System > 60kW',
    value: true
  }
];

export const imagesTypesAllowed = 'image/jpeg, image/png, image/jpg';

export const allowedImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
export const allowedExcelTypes = [
  'text/csv',
  'text/xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
];

export const allowedImageErrorMessage = 'Invalid file type. Please select a PNG,JPG or JPEG image.';
export const allowedExcelErrorMessage = 'Invalid file type. Please select a CSV or XLSX file.';

export const DocumentTypes = {
  AccreditationDocument: 5,
  ProofOfBusinessAddress: 6,
  PublicLiabilityInsurance: 7,
  OtherProfileDocuments: 8,
  Other: 9,
  Signature: 1,
  InstallerSelfie: 4,
  ProfileImage: 10,
  ASOLicense: 11,
  ASOLicenseFront: 12,
  ASOLicenseBack: 13,
  DriverLicense: 14,
  DriverLicenseFront: 15,
  DriverLicenseBack: 16,
  ElectricalLicense: 17,
  ElectricalLicenseFront: 18,
  ElectricalLicenseBack: 19,
  PlumberLicense: 20,
  PlumberLicenseFront: 21,
  PlumberLicenseBack: 22
};

export const libraries = ['places'];
export const authTypes = {
  requestTypeEmail: 1,
  requestTypeMobile: 2,
  otpTypeSignup: 1,
  otpTypeReset: 2,
  UpdateMobileNoVerification: 7
};

export const accessMessage = "unAuthorized -  You don't have access to perform this activity";

export const mobileNumberTypes = ['+61', '+64'];

export const resellerTypes = {
  Wholesaler: 1,
  Saas: 2,
  Company: 3
};

export const ElectricianStates = {
  ACT: 1,
  NSW: 2,
  NT: 3,
  QLD: 4,
  SA: 5,
  TAS: 6,
  VIC: 7,
  WA: 8
};

export const UserDocumentStatus = {
  Pending: 1,
  Approved: 2,
  Rejected: 3
};

export const pageSizes = [10, 25, 50, 100];

export const AppName = 'Form Panda';

export const booleanArray = [
  { id: 1, name: 'Yes' },
  { id: 0, name: 'No' }
];
