import { Loader } from '@progress/kendo-react-indicators';
import React from 'react';
import Modal from 'react-bootstrap/Modal';

import styles from './modal.module.css';

const LoaderModal = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className={styles.modalWrapper}>
          <Modal className={styles.customModalBody} show={true} fullscreen={true}>
            <Modal.Body>
              <Loader type='converging-spinner' />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default LoaderModal;
