import { APP_ROUTES } from 'config/app-routes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SpinnerLoader from 'components/shared-components/spinner-loader';

import { authToken } from 'redux/auth/authSlice';
import { setUser } from 'redux/user/userSlice';

import { clearPersistedStorage } from 'utils/utils';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await clearPersistedStorage();
    dispatch(setUser(null));
    dispatch(authToken(null));
    navigate(APP_ROUTES.public.HOME, { replace: true });
    await localStorage.clear('accessToken');
    await localStorage.clear('persist:root');
  };

  useEffect(() => {
    handleLogout();
  }, []);
  return (
    <div className='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
      <SpinnerLoader />
      logging out.....
    </div>
  );
};

export default Logout;
