import { FourOFour } from 'assets';

import { LogoWhite } from 'assets/svg';

import styles from './not-found.module.css';

const NotFound = () => {
  return (
    <div className={styles.container}>
      <LogoWhite width={200} height={120} className={styles.logoImage} />
      <div className={styles.infoContainer}>
        <div className={styles.textContainer}>
          <h1 className={styles.heading}>Page Not Found</h1>
          <p className={styles.text}>
            The page you are looking for might have been removed had its name changed or is
            temporarily unavailable.
          </p>
        </div>

        <img src={FourOFour} alt='not found' className={styles.image} />
      </div>
    </div>
  );
};

export default NotFound;
